// colors from:
// https://www.figma.com/file/lfOoLLm5Bhv0cFll1mWfFD/Presence-Material-Design?node-id=140%3A15077
// https://presencelearning.atlassian.net/browse/CORE-110

// Colors
$red-1: #ff5a60;

$red-1-100: #ffcecf;
$red-1-200: #ffadb0;
$red-1-300: #ff8c90;
$red-1-400: #ff7378;
$red-1-500: $red-1;
$red-1-600: #ff5258;
$red-1-700: #ff484e;

$red-2: #ff7b80;
$red-3: #ffadb0;
$red-4: #ffdedf;
$red-5: #fdf0f0;
$red-6: #d62b31;

$blue-1: #020535;
$blue-2: #0070e0;

$blue-2-100: #b3d4f6;
$blue-2-200: #80b8f0;
$blue-2-300: #4d9be9;
$blue-2-400: #2685e5;
$blue-2-500: $blue-2;
$blue-2-600: #0068dc;
$blue-2-700: #005dd8;

$blue-3: #0583ff;
$blue-4: #379cff;

$blue-5: #82c1ff;

$blue-5-100: #daecff;
$blue-5-200: #c1e0ff;
$blue-5-300: #a8d4ff;
$blue-5-400: #95caff;
$blue-5-500: $blue-5;
$blue-5-600: #7abbff;
$blue-5-700: #6fb3ff;
$blue-5-800: #65abff;
$blue-5-900: #529eff;

$blue-6: #d0e6fd;

$blue-6-100: #f1f8fe;
$blue-6-200: #e8f3fe;
$blue-6-300: #deeefe;
$blue-6-400: #d7eafd;
$blue-6-500: $blue-6;
$blue-6-600: #cbe3fd;
$blue-6-700: #c4dffc;
$blue-6-800: #bedbfc;
$blue-6-900: #b3d5fc;

$blue-7: #0b4fbd;

$green-1: #75d002;
$green-2: #91d935;
$green-3: #bae880;
$green-4: #e3f6cc;

$yellow-1: #ffd952;
$yellow-2: #ffe175;
$yellow-3: #ffeca8;
$yellow-4: #fff4cb;

$purple-1: #894beb;
$purple-2: #a06fef;
$purple-3: #c4a5f5;
$purple-4: #e7dbfb;

$teal-1: #2ab8c3;
$teal-2: #55c6cf;
$teal-3: #95dce1;
$teal-4: #d4f1f3;

$gray-0: #121212;
$gray-1: #26292f;
$gray-2: #313235;
$gray-3: #44454b;
$gray-4: #50515b;
$gray-5: #72727b;
$gray-6: #cccfd4;
$gray-7: #e6e6ec;
$gray-7-5: #edeeef;
$gray-8: #f5f7fa;
$gray-9: #fbfdff;
$gray-10: #f7f7f7;
$gray-11: #747678;

$black: #000000;
$white: #ffffff;

$success-green: #579900;
$warning-yellow: $yellow-1;

$warning-orange: #bb573e;
$warning-orange-100: #ebcdc5;
$warning-orange-700: #ac4630;

$error-red: #d62b31;

// Spacing Sizes
// TODO: Use rem values once html font-size is set in base.scss
$s-1: 4px;
$s-2: 8px;
$s-3: 12px;
$s-4: 16px;
$s-5: 24px;
$s-6: 32px;
$s-6-5: 42px;
$s-7: 48px;
$s-8: 54px;
$s-9: 60px;

// Fonts Faces ------------------------------------------
$ff-base: 'Avenir', 'Helvetica', 'Arial', sans-serif;
$ff-heading: $ff-base;
$ff-marketing-heading: 'Tiempos Headline', 'Helvetica', 'Arial', sans-serif;
$ff-special: 'Roboto Condensed', 'Avenir', 'Helvetica', 'Arial', sans-serif;

// Font Weights -----------------------------------------
$fw-normal: 400;
$fw-medium: 500;
$fw-semibold: 700;
$fw-bold: 900;

// Font sizes -------------------------------------------
// TODO: Use rem values once html font-size is set in base.scss
$fs-1: 12px;
$fs-2: 14px;
$fs-3: 16px;
$fs-4: 18px;
$fs-5: 20px;

$fs-h1: 28px;
$fs-h2: 22px;
$fs-h3: 16px;
$fs-h4: 16px;
$fs-h5: 16px;

$app-bar-height: 57px;
$nav-width: 244px;
$nav-height: 56px;
